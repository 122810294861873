import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import AddArticle from "./components/AddArticle/AddArticle";
import Topbar from "./components/Topbar/Topbar";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./components/Contact/Contact";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import Article from "./components/Article/Article";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import Categories from "./components/Categories/Categories";
import Tag from "./components/Tag/Tag";
import SmartTools from "./components/SmartTools/SmartTools";
import KeywordExtractionTool from "./components/KeywordExtractionTool/KeywordExtractionTool";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Navbar />
      <HashRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/addarticle" element={<AddArticle />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/article" element={<Article />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/tags" element={<Tag />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/smart-tools" element={<SmartTools />} />
          <Route
            path="/keyword-extraction"
            element={<KeywordExtractionTool />}
          />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </HashRouter>
      <Footer />
    </div>
  );
}

export default App;
